<template>
  <div
    class="flex flex-col items-center text-white  min-h-screen bg-cover bg-center px-5"
    :style="{'background-image': `url(${bgImg})`}"
  >
    <Logo class="w-60 mt-5 md:mt-45" />
    <div class="text-center mt-13 md:mt-15">
      <div class="flex flex-col items-center justify-between text-[16px] h-15 md:(text-[40px] h-20) font-black gap-2">
        <div>도메인 분실을 대비하여 <span class="text-[#44B307]">{{ foreverDomain }}</span>만 기억하시면</div>
        <div>언제든지 최신 도메인 확인이 가능합니다.</div>
      </div>
      <div class="w-full flex justify-center mt-8">
        <div class="border-2 rounded w-13" />
      </div>
      <div class="text-[18px] md:text-[20px] h-7 mt-6">
        현재 접속 가능한 주소
      </div>
    </div>
    <div class="w-full flex flex-col justify-center gap-4 md:(flex-row gap-8) mt-6">
      <a
        v-for="domain in domains"
        :href="`https://${domain}`"
        target="_blank"
        class="py-5 md:px-8 flex flex-col items-center justify-center bg-black/40 rounded-2xl "
      >
        <div class="text-[20px]">
          공용도메인
        </div>
        <div class="text-3xl font-black text-[#44B307]">
          {{ domain }}
        </div>
      </a>
    </div>
  </div>
</template>
<script setup>
import bgImg from '@/assets/domain/bg.png'
import Logo from "@/components/Logo.vue"
import axios from 'axios'
import {onMounted, ref} from "vue";

const foreverDomain = import.meta.env.VITE_FOREVER_DOMAIN
const domains = ref([])
onMounted(async () => {
  const {data} = await axios.get('api/domains')
  domains.value = data.data.data.map(o => o.domain)
})
</script>
